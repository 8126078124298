<template>
  <div v-loading="loading">
    <el-card shadow="never" :body-style="{ padding: '20px' }">
      <div slot="header">
        <span class="iconfont icon-Scenery">&nbsp;景点资料</span>
      </div>
      <div class="card_header_flex">
        <div>
          <el-button
            type="primary"
            size="small"
            @click="dialogAddScenery=true,isShowPublic=false"
          >新增景点</el-button>
          <el-button
            plain
            type="primary"
            size="small"
            @click="onSubmitBatchDel"
            v-show="dataLineSelections.length>0"
          >批量删除</el-button>
        </div>
        <div>
          <el-input
            v-model="search"
            placeholder="请输入景点名称"
            suffix-icon="el-icon-search"
            style="width:300px"
            size="small"
            @change="initLoad"
          ></el-input>
        </div>
      </div>
      <el-table
        :data="list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        size="mini"
        @selection-change="selectionLineChangeHandle"
        row-key="id"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
        <el-table-column prop="name" label="景点名称" width="400"></el-table-column>
        <el-table-column prop="address" label="景点地址" width="400"></el-table-column>
        <el-table-column prop="content" label="景点介绍" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="openEditScenery(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="delMyScenery(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page_right">
        <el-pagination
          background
          layout="total,prev, pager, next ,jumper"
          :total="list.length"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-card>

    <!-- 添加我的景点 -->
    <el-dialog
      :visible.sync="dialogAddScenery"
      :close-on-click-modal="false"
      width="50%"
      @closed="closeDialogAddScenery"
    >
      <span slot="title">新增景点</span>
      <div class="addScenery_view">
        <div style="width:70%">
          <el-form :model="form" ref="form" label-width="70px" size="small" hide-required-asterisk>
            <el-form-item
              label="景点名称"
              prop="name"
              :rules="{ required: true, message: '请输入景点名称', trigger: 'blur' }"
            >
              <el-input v-model="form.name" placeholder="请输入景点名称" suffix-icon="el-icon-search"></el-input>
            </el-form-item>
            <el-form-item
              label="景点地址"
              prop="address"
              :rules="{ required: true, message: '请输入景点地址', trigger: 'blur' }"
            >
              <el-input v-model="form.address" placeholder="请输入景点地址"></el-input>
            </el-form-item>
            <el-form-item
              label="景点介绍"
              prop="content"
              :rules="{ required: true, message: '请输入景点介绍', trigger: 'blur' }"
            >
              <el-input v-model="form.content" type="textarea" :rows="5" placeholder="请输入景点介绍"></el-input>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="12" :offset="0">
                <el-form-item
                  label="经度"
                  prop="lon"
                  :rules="{ required: true, message: '请输入经度', trigger: 'blur' }"
                >
                  <el-input v-model="form.lon" placeholder="请输入经度"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" :offset="0">
                <el-form-item
                  label="纬度"
                  prop="lat"
                  :rules="{ required: true, message: '请输入纬度', trigger: 'blur' }"
                >
                  <el-input v-model="form.lat" placeholder="请输入纬度"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="LOGO">
              <div style="overflow: hidden;overflow-y: auto;height: 200px">
                <el-row :gutter="0" style>
                  <el-col
                    :xl="6"
                    :lg="8"
                    :md="10"
                    :sm="24"
                    :xs="24"
                    :offset="0"
                    v-for="(item,index) in form.pictureUrl"
                    :key="index"
                    style="margin-bottom:10px;"
                  >
                    <div class="sceneryprice_view">
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="fileUrl+item"
                        fit="contain"
                      ></el-image>
                      <label @click="delSceneryImgs(index)" class="sceneryprice_del">
                        <i class="el-icon-delete" style="transform:rotate(-45deg); color:#FFFFFF;"></i>
                      </label>
                    </div>
                  </el-col>
                  <el-col
                    :xl="6"
                    :lg="8"
                    :md="10"
                    :sm="24"
                    :xs="24"
                    :offset="0"
                    style="margin-bottom:10px;"
                  >
                    <el-dropdown placement="top">
                      <div class="avatar-uploader-logo">
                        <i class="el-icon-plus avatar-uploader-logo-icon"></i>
                      </div>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          @click.native="dialogAddFileUpload=true"
                          style="padding:0"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                        <el-dropdown-item style="padding:0">
                          <el-upload
                            style="text-align:center"
                            :action="actionUrl"
                            accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                            :headers="header"
                            :data="{type:0,category:'图标'}"
                            :show-file-list="false"
                            :on-success="handleAddSuccess"
                            :before-upload="beforeAddUpload"
                            multiple
                            :limit="9"
                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="addScenery_view_right" v-show="isShowPublic">
          <el-divider>
            <span>景点库</span>
          </el-divider>
          <div
            :class="form.scenicId==item.id?'addScenery_view_right_card_selected':'addScenery_view_right_card'"
            v-for="(item,index) in publicList"
            :key="index"
            @click="seletedPublic(item)"
          >
            <div class="addScenery_view_right_badge">{{index+1}}</div>
            <div class="addScenery_view_right_content">
              <div class="addScenery_view_right_content_title">{{item.name}}</div>
              <div style="color:#909399;font-size:12px">{{item.address}}</div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="resetScenery">重 置</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddScenery">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加我的景点中添加图片 -->
    <el-dialog
      :visible.sync="dialogAddFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="false" :type="0" :category="'图标'" v-if="dialogAddFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogAddFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitAddSceneryImg" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改我的景点 -->
    <el-dialog
      :visible.sync="dialogEditScenery"
      :close-on-click-modal="false"
      width="40%"
      @closed="closeDialogEditScenery"
    >
      <span slot="title">编辑景点</span>
      <el-form
        :model="editForm"
        ref="editForm"
        label-width="70px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="景点名称"
          prop="name"
          :rules="{ required: true, message: '请输入景点名称', trigger: 'blur' }"
        >
          <el-input v-model="editForm.name" placeholder="请输入景点名称"></el-input>
        </el-form-item>
        <el-form-item
          label="景点地址"
          prop="address"
          :rules="{ required: true, message: '请输入景点地址', trigger: 'blur' }"
        >
          <el-input v-model="editForm.address" placeholder="请输入景点地址"></el-input>
        </el-form-item>
        <el-form-item
          label="景点介绍"
          prop="content"
          :rules="{ required: true, message: '请输入景点介绍', trigger: 'blur' }"
        >
          <el-input v-model="editForm.content" type="textarea" :rows="5" placeholder="请输入景点介绍"></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item
              label="经度"
              prop="lon"
              :rules="{ required: true, message: '请输入经度', trigger: 'blur' }"
            >
              <el-input v-model="editForm.lon" placeholder="请输入经度"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item
              label="纬度"
              prop="lat"
              :rules="{ required: true, message: '请输入纬度', trigger: 'blur' }"
            >
              <el-input v-model="editForm.lat" placeholder="请输入纬度"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="LOGO">
          <div style="overflow: hidden;overflow-y: auto;height: 200px">
            <el-row :gutter="0">
              <el-col
                :xl="6"
                :lg="8"
                :md="10"
                :sm="24"
                :xs="24"
                :offset="0"
                v-for="(item,index) in editForm.temporaryImg"
                :key="index"
                style="margin-bottom:10px;"
              >
                <div class="sceneryprice_view">
                  <el-image style="width: 100px; height: 100px" :src="fileUrl+item" fit="contain"></el-image>
                  <label @click="delEditSceneryImgs(index)" class="sceneryprice_del">
                    <i class="el-icon-delete" style="transform:rotate(-45deg); color:#FFFFFF;"></i>
                  </label>
                </div>
              </el-col>
              <el-col
                :xl="6"
                :lg="8"
                :md="10"
                :sm="24"
                :xs="24"
                :offset="0"
                style="margin-bottom:10px;"
              >
                <el-dropdown placement="top">
                  <div class="avatar-uploader-logo">
                    <i class="el-icon-plus avatar-uploader-logo-icon"></i>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      @click.native="dialogAddFileUpload=true"
                      style="padding:0"
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                    <el-dropdown-item style="padding:0">
                      <el-upload
                        style="text-align:center"
                        :action="actionUrl"
                        accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                        :headers="header"
                        :data="{type:0,category:'图标'}"
                        :show-file-list="false"
                        :on-success="handleEditSuccess"
                        :before-upload="beforeEditUpload"
                        multiple
                        :limit="9"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditScenery=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditScenery">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改我的景点中添加图片 -->
    <el-dialog
      :visible.sync="dialogEditFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="false" :type="0" :category="'图标'" v-if="dialogEditFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditSceneryImg" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getSceneryApi,
  getPublicSceneryApi,
  getSceneryImgApi,
  addSceneryApi,
  editSceneryApi,
  delSceneryApi
} from "../../../api/data";
import Aes from "../../../utils/aes";
import FileUpload from "../../../components/FileUpload";
export default {
  components: {
    FileUpload
  },
  data() {
    return {
      loading: true,
      search: "", //查询条件
      currentPage: 1, //当前页
      pageSize: 10, //一页显示多少条
      list: [], //我的景点库
      dataLineSelections: [], //表格选中集合
      dialogAddScenery: false, //添加景点窗口
      form: {
        name: "",
        address: "",
        content: "",
        lat: "",
        lon: "",
        scenicId: "", //选中的公共ID
        pictureUrl: []
      },
      publicList: [], //公共资源库
      dialogAddFileUpload: false, //添加景点图片窗口
      isShowPublic: false, //显示公共库
      isWatchSearch: true, //是否输入景点名称自动查询
      dialogEditScenery: false, //修改景点窗口
      dialogEditFileUpload: false, //修改景点图片窗口
      editForm: {
        name: "",
        address: "",
        content: "",
        lat: "",
        lon: "",
        scenicId: "", //选中的公共ID
        pictureUrl: [],
        temporaryImg: []
      }
    };
  },
  watch: {
    "form.name"(newVal) {
      if (this.isWatchSearch) {
        this.initPublicScenery(newVal);
      } else {
        this.isWatchSearch = true;
      }
    }
  },
  computed: {
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    }
  },
  created() {
    this.initLoad();
  },
  methods: {
    //初始化我的景点
    initLoad() {
      getSceneryApi({
        groupId: this.placesInfo.groupId,
        name: this.search
      }).then(res => {
        this.loading = false;
        this.list = res.data;
      });
    },
    //查询公共景点库
    initPublicScenery(name) {
      this.isShowPublic = true;
      getPublicSceneryApi({ name: name }).then(res => {
        this.publicList = res.data;
      });
    },
    //添加我的景点
    onSubmitAddScenery() {
      this.form.groupId = this.placesInfo.groupId;
      addSceneryApi(this.form).then(res => {
        if (res) {
          this.dialogAddScenery = false;
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },
    //关闭新增景点窗口
    closeDialogAddScenery() {
      this.$refs["form"].resetFields();
      this.form.pictureUrl = [];
    },
    //重置表单
    resetScenery() {
      this.isWatchSearch = false;
      this.$refs["form"].resetFields();
      this.form.scenicId = "";
      this.form.pictureUrl = [];
    },
    //删除景点图片
    delSceneryImgs(index) {
      this.form.pictureUrl.splice(index, 1);
    },
    //批量删除我的景点
    onSubmitBatchDel() {
      this.$confirm("此操作将永久删除该景点, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delSceneryApi({ ids: this.dataLineSelections }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      });
    },
    //删除我的景点
    delMyScenery(row) {
      this.$confirm("此操作将永久删除该景点, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        const myScenery = [];
        myScenery.push(row.id);
        delSceneryApi({ ids: myScenery }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      });
    },
    //选中公共景点资源库
    seletedPublic(item) {
      this.form.scenicId = item.id;
      this.form.name = item.name;
      this.form.address = item.address;
      this.form.content = item.content;
      this.form.lat = item.lat;
      this.form.lon = item.lon;
      this.isWatchSearch = false;
      getSceneryImgApi({ id: item.id }).then(res => {
        if (res) this.form.pictureUrl = res.data;
      });
    },
    //打开修改景点窗口
    openEditScenery(row) {
      this.editForm.id = row.id;
      this.editForm.name = row.name;
      this.editForm.address = row.address;
      this.editForm.content = row.content;
      this.editForm.lat = row.lat;
      this.editForm.lon = row.lon;
      this.editForm.scenicId = row.id;
      var array = row.pictureUrl.split(",");
      var temporaryImg = [];
      for (let i = 0; i < array.length; i++) {
        temporaryImg.push(array[i]);
      }
      this.editForm.temporaryImg = temporaryImg;
      this.editForm.pictureUrl = [];
      this.dialogEditScenery = true;
    },
    //关闭修改景点窗口
    closeDialogEditScenery() {
      this.$refs["editForm"].resetFields();
    },
    //删除编辑景点图片
    delEditSceneryImgs(index) {
      this.editForm.temporaryImg.splice(index, 1);
    },
    //提交修改景点
    onSubmitEditScenery() {
      this.editForm.pictureUrl = this.editForm.temporaryImg;
      editSceneryApi(this.editForm).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.dialogEditScenery = false;
          this.initLoad();
        }
      });
    },
    //表格多选框选中事件
    selectionLineChangeHandle(val) {
      const selectionList = [];
      for (let i = 0; i < val.length; i++) {
        selectionList.push(val[i].id);
      }
      this.dataLineSelections = selectionList;
    },
    //添加我的景点完成资源库添加图片事件 （添加多张图片）
    onSubmitAddSceneryImg() {
      this.dialogAddFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        for (let i = 0; i < this.$store.state.fileList.length; i++) {
          this.form.pictureUrl.push(this.$store.state.fileList[i].filePath);
        }
        this.$store.commit("setFileList", []);
      }
    },
    //图片窗口关闭事件
    closeDialogFileUpload() {
      this.$store.commit("setFileList", []);
    },
    //上传完成事件
    handleAddSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.form.pictureUrl.push(result.msg);
      } else {
        this.$message.error(result.msg);
      }
       this.initLoad();
    },
    //上传前事件
    beforeAddUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },

    //修改我的景点完成资源库添加图片事件（添加多张图片）
    onSubmitEditSceneryImg() {
      this.dialogAddFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        for (let i = 0; i < this.$store.state.fileList.length; i++) {
          this.editForm.pictureUrl.push(this.$store.state.fileList[i].filePath);
        }
        this.$store.commit("setFileList", []);
      }
    },
    //上传完成事件
    handleEditSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.editForm.pictureUrl.push(result.msg);
      } else {
        this.$message.error(result.msg);
      }
       this.initLoad();
    },
    //上传前事件
    beforeEditUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },

    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    }
  }
};
</script>
<style lang='scss'>
.addScenery_view {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 0px 0px;
}
.addScenery_view_right {
  width: 29%;
  border-left: 1px dashed #d9d9d9;
  padding: 0 10px;
  overflow: hidden;
  overflow-y: auto;
  height: 500px;
}

.addScenery_view_right_card {
  border: 1px solid #ebeef5;
  border-radius: 4px;
  color: #303133;
  margin-bottom: 5px;
  position: relative;
}
.addScenery_view_right_card:hover {
  box-shadow: 0 2px 12px 0 #619aec8c;
}

.addScenery_view_right_card_selected {
  border: 1px solid #ebeef5;
  border-radius: 4px;
  color: #303133;
  margin-bottom: 5px;
  position: relative;
  background: #fffcf5;
}

.addScenery_view_right_badge {
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.addScenery_view_right_content {
  padding: 22px 10px 10px 10px;
  cursor: pointer;
}
.addScenery_view_right_content_title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}
.avatar-uploader-logo {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.avatar-uploader-logo:hover {
  border-color: #5e72f9;
}
.avatar-uploader-logo-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.sceneryprice_view {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 100px;
  height: 100px;
  background: rgba(235, 235, 235, 0.5);
  border-radius: 6px;
  border: 1px dashed #d9d9d9;
}
.sceneryprice_view:hover {
  border-color: #5e72f9;
}

.sceneryprice_del {
  width: 50px;
  height: 30px;
  background: #f56c6c;
  transform: rotate(45deg);
  position: absolute;
  right: -18px;
  top: -9px;
  text-align: center;
  padding-top: 4px;
  box-shadow: 0 1px 1px #ccc;
  cursor: pointer;
}
</style>